@import url(Assets/Fonts/stylesheet.css);

:root {
  scroll-behavior: smooth;

  --primary: #F58E23;
  --primaryHover: #F05A22;

  --black: #000;
  --grey: #353838;
  --bs-body-font-size: 14px;
  --sidebarWidth: 300px;
  --bs-success-rgb: 0, 178, 149;
  --bs-danger-rgb: 215, 51, 29;
}

img,
svg {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primaryHover);
  --bs-btn-hover-border-color: var(--primaryHover);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primaryHover);
  --bs-btn-active-border-color: var(--primaryHover);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary);
  --bs-btn-disabled-border-color: var(--primary);
}

.btn-outline-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary);
  --bs-btn-active-border-color: var(--primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary);
  --bs-gradient: none;
}


.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #D7331D;
  --bs-btn-border-color: #D7331D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a22616;
  --bs-btn-hover-border-color: #a22616;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #a22616;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #D7331D;
  --bs-btn-disabled-border-color: #D7331D;
}

.btn-outline-danger {
  --bs-btn-color: #D7331D;
  --bs-btn-border-color: #D7331D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #D7331D;
  --bs-btn-hover-border-color: #D7331D;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #D7331D;
  --bs-btn-active-border-color: #D7331D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #D7331D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D7331D;
  --bs-gradient: none;
}


.btn:focus {
  box-shadow: none;
}


body {
  font-family: 'Avenir Next';
  background: #f6f9ff;
  color: var(--grey);
}

a {
  color: #F58E23;
  text-decoration: none;
}

a:hover {
  color: #F05A22;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Avenir Next';
  font-weight: 700;
}

p:last-of-type {
  margin-bottom: 0;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #012970;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #F58E23;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  /* text-align: center; */
  font-size: 15px;
  padding: 10px 15px;
}

.dropdown-menu .dropdown-footer a {
  color: var(--grey);
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

button.noStyle {
  border: 0;
  background-color: transparent;
  padding: 0;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: var(--grey);
  padding: 15px;
}

.card-title {
  padding: 0 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  font-weight: 700;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 30px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-size: 20px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus,
.form-check-input:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #F58E23;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 15px;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 14px;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #F58E23;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #F58E23;
  border-bottom: 2px solid #F58E23;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 180px;
  }
}

.logo img {
  max-height: 30px;
}



.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding: 10px 20px;
  /* Toggle Sidebar Button */
  /* Search Bar */
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

.dashCard .card-title {
  color: var(--grey);
}




@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #012970;
}

/*--------------------------------------------------------------
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  /* color: #012970; */
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--grey);
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 68px;
  right: 9px;
}

.nav-profile {
  cursor: pointer;
  user-select: none;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--grey);
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  width: var(--sidebarWidth);
  z-index: 996;
  transition: all 0.3s;
  padding: 20px 0;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: var(--sidebarWidth);
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {

  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--grey);
  transition: 0.3;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: #F58E23;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #F58E23;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #F58E23;
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link.active i {
  color: #F58E23;
  pointer-events: none;
}

.list_item:has(ul)>.nav-link.active {
  pointer-events: all;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  /* color: #012970; */
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #F58E23;
}

.sidebar-nav .nav-content a.active i {
  background-color: #F58E23;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #F58E23;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

strong.dot:before {
  width: 10px;
  height: 10px;
  background: #ff0000;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  content: "";
}

strong.dot.online:before {
  background: #0bd90b;
}

button.link {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

/* Info Cards */
.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  font-size: 26px;
  color: var(--grey);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  line-height: 0;
  flex-shrink: 0;
  flex-grow: 0;
  flex: 0 0 32px;
  max-width: 32px;
}

.dashboard .sales-card .card-icon svg {
  font-size: 32px;
}

.dashboard .sales-card .card-icon svg {
  color: #F58E23;
}

.dashboard .sales-card .card-icon small {
  display: block;
  text-align: center;
  margin-top: 12px;
}


.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item+.post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #012970;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #F58E23;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #012970;
  padding: 15px 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #F58E23;
}

.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #F58E23;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #012970;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #F58E23;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #F58E23;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #F58E23;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}



.custom_alert {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 15px 30px;
  background: #62a965;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 20%) 2px 2px 10px;
  min-width: 200px;
  text-align: center;
  font-weight: 500;
  transform: translate(-50%, 0);
  animation: alert 3.1s linear;
  z-index: 9999999999999;
}

.custom_alert.error {
  background: red;
}

@keyframes alert {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -100%);
  }

  5% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  94% {
    opacity: 1;
    transform: translate(-50%, 20px);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}

.custom_alert p {
  margin: 0;
}

.paymentLogo img {
  display: block;
  height: 48px;
}

.btnTransparent {
  background: transparent;
  border: 0;
}

.cardDetail__icon {
  display: inline-block;
  margin-right: 2px;
  width: 35px;
}

.cardDetailHolder {
  display: flex;
  gap: 5px;
  max-width: 340px;
  flex-wrap: wrap;
}

.cardDetail {
  display: inline-flex;
  align-items: center;
  padding: 2px 5px;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  box-shadow: 0px 4px 10px -4px rgba(0, 0, 0, 0.25);
}

.cardDetail__icon img {
  max-height: 18px;
  display: block;
}

.customMode .form-switch .form-check-input {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

main#main {
  position: relative;
}

.loader-line {
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: #ddd;
  margin: 0;
  border-radius: 20px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 998;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #f58e23;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 80%;
  }

  100% {
    left: 100%;
    width: 100%;
  }
}

.search-bar {
  width: 100%;
  max-width: 360px;
  padding: 0 20px 0 0;
}

.search-form {
  width: 100%;
}

.search-form input {
  font-size: 14px;
  padding: 7px 38px 7px 8px;
  /* border-radius: 3px; */
  transition: 0.3s;
  /* width: 100%; */
}

.search-form {
  position: relative;
}

.searchBarIcon {
  border: 0;
  padding: 0;
  background: none;
  pointer-events: none;
  position: absolute;
  right: 9px;
  margin: 0;
  top: 4px;
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #F58E23;
  border-color: #F58E23;
}

.react-datepicker-popper {
  z-index: 10;
}

.form-control:focus {
  box-shadow: none;
}

.sortingSelect {
  border: 0;
  border-bottom: 1px solid #000;
  background: #fff;
  padding: 0 10px;
}

.sortingSelect:focus {
  border: 0;
  border-bottom: 1px solid #000;
  outline: 0;
}

.sortingSelect+.sortingSelect {
  margin-left: 10px;
}

.invalid-feedback {
  color: #dc3545;
  font-size: .9em;
}

table.table tr td,
table.table tr th {
  white-space: nowrap;
}

.form-control[readonly] {
  background: #f5f5f5;
  border: 0;
}

.textClear {
  font-weight: bold;
  color: red;
  border: 0;
  background: transparent;
  font-size: 12px;
}

.textClear:hover,
.textClear:focus {
  color: red;
  border: 0;
  background: transparent;
}

.userdetailTopRow__right .form-check.form-switch {
  margin-bottom: 0;
}

.alert-heading {
  font-size: 14px;
  font-weight: 600;
}

.badge {
  line-height: normal;
  font-weight: normal;
  padding: 5px 12px;
  border-radius: 30px;
}

.badge-small {
  padding: 3px 8px;
}

.card-title span.userName {
  color: var(--black);
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

a.page-link {
  color: #000;
  box-shadow: none !important;
}

table.v-center tr td {
  vertical-align: middle;
}

.qr_code_outer .ref {
  font-size: 18px;
}

.qr_code_outer .ref2 {
  font-size: 14px;
  margin-bottom: 0
}

.qr_code_outer .qr_code_img {
  border: #ddd 1px solid;
  border-radius: 10px
}

.qr_code_outer .qr_code_img_outer {
  padding: 5px;
  margin: 20px 0 10px
}

.modal-header .btn-close {
  background-size: 50%;
}

.sidebar-nav .nav-link .nav-link-icon {
  width: 25px;
  text-align: center;
}

.chargers_assignmentOverFlow {
  /* max-height: 340px; */
  overflow: auto;
  background-color: #f9f9f9;
}

.chargers_assignment label {
  display: block;
  cursor: pointer;
}

.form-control {
  font-size: 14px;
}

.passwordField {
  position: relative;
}

.passwordField__btn {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 1px 5px;
  color: #787878;
}

.form-select {
  font-size: 14px;
}

input.reportingSelectBox {
  border: 1px solid #dee2e6;
  border-radius: 0;
  cursor: pointer;
  max-width: 180px;
  padding: 5px 10px;
  width: 100%;
}

input.reportingSelectBox:focus {
  outline: none;
}

.react-tel-input input.form-control {
  width: 100%;
}

.loginPageContainer {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.copartnerTable {
  min-width: 100%;
  width: max-content;
}

.pagetitle .btn {
  white-space: nowrap;
}

.shipping-progress--style {
  display: flex;
}

.special-offer--product {
  flex-grow: 1;
}

.feedbackImgHolder {
  display: grid;
  grid-template-columns: 60px 60px 60px;
  grid-template-rows: 60px;
  gap: 5px;
}

.feedbackImgHolder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.pswp img {
  /* max-width: none;  */
  object-fit: contain;

}

.pswp img {
  max-width: 100vw !important;
  max-height: 100vh !important;
  height: auto !important;
  width: auto !important;
  position: static !important;
}

.pswp__zoom-wrap {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: none !important;
  width: 100% !important;
}

.feedbackImagesGrid {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.feedbackImagesGrid__left {
  flex: 0 0 20%;
  max-width: 20%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
}

.feedbackImagesGrid__left>div {
  flex: 0 0 calc(50% - 5px);
  max-width: calc(50% - 5px);
  border: 2px solid transparent;
  cursor: pointer;
}

.feedbackImagesGrid__left>div.active {
  border-color: #F58E23;
}

.feedbackImagesGrid__right {
  flex: 0 0 calc(80% - 10px);
  max-width: calc(80% - 10px);
  text-align: center;
}

.feedbackImagesGrid__right img {
  max-height: 90vh;
  max-height: 600px;
  margin: 0 auto;
  text-align: center;
}

.feedbakPop+.modal-backdrop {
  z-index: 1055;
}

.feedbakPop+.modal-backdrop {
  z-index: 1055;
}

.feedbakPop+.modal-backdrop+.modal {
  z-index: 1056;
}

.dataTable td.feedbackText {
  max-width: 260px;
  white-space: break-spaces;
  min-width: 210px;
}

.logoUpdateBox {
  position: relative;
  /* */
  display: flex;
  align-items: center;
}

.feedbackTable table.table {
  /* min-width: 970px; */
}

.logoUpdateBox__img {
  flex: 0 0 100px;
  max-width: 100px;
}

.logoUpdateBox__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logoUpdateBox__btnHolder {
  margin-left: 10px;
  gap: 10px;
}

.logoUpdateBox .deleteBtn {
  width: 30px;
  height: 30px;
  padding: 2px;
  border-radius: 15px;

}

.logoUpdateBox .uploadBtn {
  width: 30px;
  height: 30px;
  padding: 2px;
  border-radius: 15px;

}

.extrasmall.small {
  font-size: .775em;
}

.starRatingHolder {
  display: flex;
  gap: 1px;
  white-space: nowrap;
  color: #F58E23;
}

.hideLastBorder tbody>tr:last-child td {
  border-bottom: 0;
}

.chargeStationTitle {
  --width: 60px;
}

.chargeStationTitle__icon {
  background: transparent;
  border: 0;
  flex: 0 0 var(--width);
  max-width: var(--width);
  font-size: 20px;
  color: #F58E23;
  position: relative;
}

.chargeStationTitle__btn {
  background: #198754;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: #ffff;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.chargeStationTitle__cntnt {
  flex: 0 0 calc(100% - var(--width));
  max-width: calc(100% - var(--width));
  padding-left: 10px;
}

.chargeSearchBox {
  max-width: 600px;
  gap: 10px;
  flex-wrap: wrap;
}

.chargeSearchBox .search-form {
  flex: 230px;
  max-width: calc(50% - 50px);
}

.addnotificationPriview {}

.addnotificationPriview img {
  width: auto;
  max-height: 100px;
  max-width: 100%;
  object-fit: contain;
}

.modalTitleCstm {
  font-family: 'Avenir Next';
  font-weight: 700;
}

.notificationImage img {
  max-height: 50px;
}

.appointmentHolder__no {
  flex: 0 0 30px;
}

.appointmentHolder__cntnt {
  flex: 0 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
}

.notificationTableBtn {
  display: grid;
  grid-template-columns: 80px 40px;
}

.breakWhitespace {
  white-space: break-spaces;
}

.logoUpdateBox__img.logoUpdateBox__grid {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.logoUpdateBox__Box {
  position: relative;
  flex-basis: 20%;
  max-width: calc(20% - 5px);
}

.logoUpdateBox__Box>.deleteBtn {
  position: absolute;
  inset: -6px 0px auto auto;
  width: 25px;
  height: 25px;
  padding: 0px;
}

.logoUpdateBox__Box.logoUpdateBox__btnHolder {
  display: flex;
  align-items: center;
  background: #e9e9e9;
  justify-content: center;
  margin: 0;
  padding: 5px;
}

.logoUpdateBox__img.logoUpdateBox__grid img {
  height: 60px;
}

.imageContainer .chargeStationTitle__cntnt {
  flex-basis: 100%;
  max-width: 100%;
}

.charginStationCard {
  border-radius: 0;
}

.charginStationCard+.charginStationCard {
  border-top: 1px solid #ddd;
}

.wallet_accordion .accordion-button,
.wallet_accordion .accordion-body {
  padding: 0;
}

.wallet_accordion .accordion-button:after {
  position: absolute;
  right: 8px;
}

.wallet_accordion .accordion-item {
  margin-bottom: 10px;
}

.btn.link {
  color: var(--grey);
  text-decoration: underline;
}

.wallet_accordion {
  background-color: #eee;
  border: transparent 1px solid;
  padding: 0;
  margin-bottom: 5px;
  border-radius: 10px;
}

.wallet_accordion_outer {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.wallet_accordion_panel {
  border: #ddd 1px solid;
  border-radius: 10px;
  overflow: hidden;
}

.text-capitalized {
  text-transform: capitalize;
}

table.table.dataTable.linkRow td>a,
table.table.dataTable.linkRow tbody th>a {
  display: block;
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color);
  transition: 0.2s all ease-in-out;
}

/* table.table.dataTable.linkRow td>a:hover,
table.table.dataTable.linkRow tbody th>a:hover,
table.table.dataTable.linkRow tbody tr:hover>a {
  color: var(--primaryHover); 
} */

table.table.dataTable.linkRow tbody tr td,
table.table.dataTable.linkRow tbody tr th {
  transition: 0.2s all ease-in-out;
}

table.table.dataTable.linkRow tbody tr:hover td,
table.table.dataTable.linkRow tbody tr:hover th {
  background-color: #f6f9ff;
}

/* table.table.dataTable.linkRow tbody tr:hover td>a, table.table.dataTable.linkRow tbody tr:hover th>a {
  color: var(--primaryHover);

} */

table.table.dataTable.linkRow td,
table.table.dataTable.linkRow tbody th {
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  min-height: 120px;
}

textarea.resize-none {
  resize: none;
}

li.page-item.active a.page-link {
  /* pointer-events: none; */
}

.inputWithText {
  position: relative;
}

.inputWithText__text {
  position: absolute;
  right: 11px;
  top: 10px;
  font-size: small;
  color: #878787;
}

.inputWithText .form-control {
  padding-right: 40px;
}

.inputWithText .form-control.is-invalid+.inputWithText__text {
  opacity: 0;
}


@keyframes loading {
  40% {
    background-position: 100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.loadingTd {
  position: relative;
}

.loadingTd:after {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 24px;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%);
  background-size: 200px 24px;
  background-position: -100px 0;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
}

.loadingTd .bar {
  background-color: #E7E7E7;
  height: 14px;
  width: 100%;
}

.infinite-scroll-component {
  background: #f9f9f9;
  padding: 10px 15px;
}

.infinite-scroll-component .form-check:not(:last-of-type) {
  border-bottom: 1px solid #ddd;

}

.infinite-scroll-component .form-check {
  display: block;
  padding-block: 3px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


section.section.dashboard .card.info-card.sales-card {
  margin-bottom: var(--bs-gutter-x);
  height: calc(100% - var(--bs-gutter-x));
}

.section.dashboard {
  margin-bottom: 10px;
}

.section.dashboard .card-body {
  padding: 15px 15px;
}

.dashBarChartHolder canvas {
  display: block;
}

.form-check-input:checked {
  background-color: #00b295;
  border-color: #00b295;


}


table.table .fixedWidth {
  max-width: 120px;
  white-space: pre-wrap;
}

table.table .fixedWidth200 {
  max-width: 200px;
}

input.form-check-input {
  cursor: pointer;
}

.chart-table {
  max-height: 400px;
  overflow: auto;
}

.chart-table thead {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 9;
}

.font-reg {
  font-weight: 400;
}

.font-med {
  font-weight: 600;
}

.date-range-outer {
  flex: 0 0 200px;
}

.date-range-outer .react-datepicker-wrapper {
  width: 100%;
}

.gauge_text {
  font-size: 14px;
}
.TimeSeriesHeatmap{
  min-height: 450px;
}

@media only screen and (min-width: 1400px) {
  .order-xxl-6 {
    order: 6;
  }
}

@media only screen and (min-width: 1199px) {
  .header .btnTransparent {
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  :root {
    --sidebarWidth: 250px;


  }

}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 768px) {
  .userdetailTopRow {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .userdetailTopRow__right {
    margin: 0 0 20px;
  }

  .card {
    margin-bottom: 20px;
  }

  .card-body {
    padding: 20px 15px;
  }

  #main {
    /* margin-top: 30px; */
    padding: 20px 15px;
  }

  .dashboard .info-card h6 {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  .dashboard .card-title {
    font-size: 16px;
  }

  .dashboard .info-card .ps-3 {
    padding-left: 7px !important;
  }

  .dashboard .info-card {
    padding-bottom: 10px;
    height: calc(100% - 30px);
  }

  .table>:not(caption)>*>* {
    padding: 0.5rem 1rem;
  }

  .custom_alert {
    top: 7px;
    padding: 15px 10px;
    min-width: 210px;

  }

  .feedbackImagesGrid__left {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .feedbackImagesGrid__right {
    flex-basis: calc(70% - 10px);
    max-width: calc(70% - 10px);
  }

  .logoUpdateBox__Box {
    max-width: calc(33% - 5px);
    flex-basis: 33%;
  }

  .dashboard .sales-card .card-icon svg {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .cardDetail__icon {
    width: 25px;
  }

  span.cardDetail__text {
    font-size: 12px;
  }

  ul.pagination {
    flex-wrap: wrap;
  }

  ul.pagination li.page-item {
    margin-bottom: 10px;
  }

  .pagetitle h1 {
    font-size: 20px;
  }

  .feedbackImagesGrid {
    flex-direction: column-reverse;
  }

  .feedbackImagesGrid__left {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 10px;
    flex-wrap: nowrap;
    gap: 5px;
    overflow: hidden;
    overflow-x: auto;
  }

  .feedbackImagesGrid__left>div {
    flex-basis: 90px;
    max-width: 90px;
  }

  .feedbackImagesGrid__right {
    flex-basis: 100%;
    max-width: 100%;
  }

  .feedbackImagesGrid__right img {
    max-height: initial;
    width: 100%;
  }

  .logoUpdateBox__Box {
    max-width: calc(50% - 5px);
    flex-basis: 50%;
  }

  .gauge_text {
    font-size: 12px;
  }

  .dashCard>.d-flex {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .date-range-outer {
    flex: auto;
  }
  .TimeSeriesHeatmap{
    min-height: 300px;
  }
}

@media only screen and (max-width: 480px) {
  .pagetitle h1 {
    font-size: 18px;
  }

  .dashboard .info-card h6 {
    font-size: 18px;
    line-height: 1;
  }
}

@media only screen and (max-width: 375px) {
  .dashboard .card-title {
    font-size: 14px;
  }

  .dashboard.card-body {
    padding: 17px 10px;
  }

  .section.dashboard .card-body {
    padding: 12px;
  }
}